<template>
  <div>
    <div v-if="type != 'show'">
      <div class="flex al-c">
        <div class="" v-for="(v, i) in valueList" :key="i">
          <div
            class="temp"
            :class="['li' + v.value]"
            @click="curValue = v.value"
          >
            {{ v.name }} <span v-if="curValue === v.value">(选中)</span>
          </div>
        </div>
        <el-button size="small" type="primary" @click="reset"
          >全部重置</el-button
        >
      </div>
      <p>选择要设置的类型，然后点击下方时间段设置预约信息</p>
    </div>
    <div v-if="setPeople && info.length > 0">
      <el-switch
        style="display: block"
        v-model="setCount"
        active-color="#13ce66"
        inactive-color="#eee"
        active-text="设置预约人数"
        inactive-text=""
      >
      </el-switch>
      <p style="m-t5">点击下方时间段设置设备的预约人数信息</p>
    </div>

    <div class="data-wrap flex">
      <div class="table-time">
        <div class="time-item" v-for="(h, index) in hours" :key="index">
          {{ h }}
        </div>
      </div>
      <div class="table-wrap" v-for="(item, i) in info" :key="i">
        <ul class="table-device-item">
          <li class="device-name">{{ item.device.name }}</li>
          <li
            class="d-item"
            :class="['li' + d.v]"
            v-for="(d, k) in item.data"
            :key="k"
            @click="settingData(d, item.device)"
          >
            {{ caseMap[d.v] }}
            <div v-if="d.count">已约{{ d.count }}/{{ d.max }}</div>
          </li>
        </ul>
      </div>
    </div>
   
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form">
        <el-form-item label="">
          <p>{{ curDevice.name }}：{{ curDate.s }}-{{ curDate.e }}</p>
          <p>已预约:{{ curDate.count }}人</p>
          <p>设备预约人数上限:{{ curDevice.max_people }}人</p>
        </el-form-item>
        <el-form-item label="修改已预约人数">
          <el-input
            v-model.number="count"
            type="number"
            placeholder="设备已预约人数"
          ></el-input>
          <div>填写设备在该时间段的预约人数</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateCount">更 新</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    startTime: {
      type: String,
      default: "",
    },
    changeTime: {
      type: String,
      default: "",
    },
    endTime: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "add",
    },
    setPeople: {
      type: Boolean,
      default: false,
    },
    devices: {
      type: Array,
      default: () => {
        return [];
      },
    },
    datas: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      setCount: false,
      valueList: [
        { name: "不开放", value: 0 },
        { name: "闲时价", value: 1 },
        { name: "高峰价", value: 2 },
      ],
      caseMap: {
        0: "不开放",
        1: "闲时价",
        2: "高峰价",
        3: "已约满",
      },
      curValue: 1,
      hours: [],
      info: [], // [{ device: {}, data: [{s,e,v}] }]
      timeData: [],
      dialogVisible: false,
      curDevice: {},
      curDate: {},
      count: "",
    };
  },
  watch: {
    datas: {
      handler: function (nv, ov) {
        if (nv.length > 0) {
						const s = new Set();
						nv.forEach(item => {
							item.data.forEach((v) => {
								s.add(v.s)
								s.add(v.e)
							});
						})

						let hours = Array.from(s);
						this.hours = hours.sort((a, b) => {
							return Number(a.split(':')[0]) - Number(b.split(':')[0])
						})

						nv.forEach(item => {
							let defObj = {}
							let itemObj = {}
							hours.forEach((h, i) => {
								if (i < hours.length - 1) {
									defObj[h] = {
										s: h,
										v: 0
									}
								}
							})
							item.data.forEach((v) => {
								itemObj[v.s] = v
							});
							let mager = Object.assign(defObj, itemObj)
							item.data = Object.values(mager)
						})
						this.info = nv;
					} else {
						this.info = [];
						this.hours = [];
					}
      },
    }
  },
  mounted() {},

  methods: {
    updateCount() {
      if (!this.count) {
        this.$message("请填写已预约人数");
        return;
      }
      if (this.count < 0) {
        this.$message("请填写已预约人数");
        return;
      }
      if (this.count > this.curDevice.max_people) {
        this.$message("预约人数不能大于设备上限");
        return;
      }
      this.$axios({
        url: "/user/serviceDeviceAppoint/setCount",
        method: "post",
        data: {
          device_id: this.curDevice.id,
          appointment_count: this.count,
          start_time:  `${this.changeTime} ${this.curDate.s}:00`,
          end_time:  `${this.changeTime} ${this.curDate.e}:00`,
        },
      }).then((res) => {
        if (this.count == this.curDevice.max_people) {
          this.curDate.v = 3;
        }
        this.curDate.count = this.count;
        this.dialogVisible = false;
        this.count = "";
      });
    },
    getAppointTemplate() {
      let res = this.info.map((item) => {
					let datas = item.data.filter(v=>v.e)
					return {
						device_id: item.device.id,
						data: JSON.stringify(datas)
					};
				});

      return res;
    },
    reset() {
      this.$confirm("确认重置所有时间段？").then((res) => {
        this.info.forEach((item) => {
          item.data.forEach((v) => {
            v.v = 1;
          });
        });
      });
    },
    settingData(time, device) {
      if (this.type != "show") {
        time.v = this.curValue;
      } else if (this.setCount) {
       if (time.v != 0) {
						this.curDate = time;
						this.curDevice = device;
						this.dialogVisible = true;
					}
      }
    },
    initInfo() {
      this.info = [];
      let s = Number(this.startTime.split(":")[0]);
      let e = Number(this.endTime.split(":")[0]);
      let hours = [];
      let timeData = [];
      for (let i = s; i < e; i++) {
        hours.push(this.zeroFill(i) + ":00");
        let item = {
          s: this.zeroFill(i) + ":00",
          e: this.zeroFill(i + 1) + ":00",
          v: 1,
        };
        timeData.push(item);
      }
      this.timeData = timeData;
      hours.push(this.zeroFill(e) + ":00");
      this.hours = hours;
      this.devices.forEach((item) => {
        let data = JSON.parse(JSON.stringify(timeData));
        this.info.push({ device: item, data });
      });
    },
    zeroFill(n) {
      return n < 10 ? "0" + n : n;
    },
  },
};
</script>
<style lang="less" scoped>
.data-wrap {
  overflow: auto;
  margin-top: 15px;
}
.table-wrap {
  display: flex;
  flex-wrap: nowrap;
}
.table-time {
  padding-top: 28px;
  .time-item {
    box-sizing: border-box;
    height: 55px;
  }
}
.table-device-item {
  width: 100px;
  flex-shrink: 0;
  margin-left: 5px;
  .d-item {
    box-sizing: border-box;
    height: 50px;
    border: 1px solid rgb(235, 235, 235);
    margin-top: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.temp {
  height: 40px;
  width: 100px;
  line-height: 40px;
  text-align: center;
  margin-right: 20px;
}
.device-name {
  box-sizing: border-box;
  height: 30px;
  padding: 5px;
  width: 100px;
  text-align: center;
}
.li0 {
  background-color: #eee;
}
.li1 {
  background-color: rgb(134, 235, 121);
}
.li2 {
  background-color: rgb(240, 193, 123);
}
.li3 {
  background-color: rgb(169, 203, 231);
}
</style>