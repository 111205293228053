<template>
  <div>
    <el-select
      filterable
      v-model="service_id"
      :placeholder="placeholder+''"
      clearable
      remote
      :remote-method="searchOrgName"
      @change="changeItem"
      ref="agentSelect"
      @hook:mounted="cancalReadOnly"
      @visible-change="cancalReadOnly"
      @focus.once="searchOrgName()"
    >
      <el-option
        v-for="(option, index) in service_list"
        :key="index"
        :value="option.id"
        :label="option.name"
        @click.native="serChnage(option)"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    condition: {
      type: Object,
      default: ()=>{return {}},
    },
    placeholder:{
      type:[String,Number],
      default:"请输入名称"
    }
  },
  data() {
    return {
      service_list: [],
      selected: "",
      service_id: "",
    };
  },
  methods: {
    clear(){
        this.service_list= []
        this.service_id= ""
      },
    setValue(item){
 this.service_list= [item]
        this.service_id= item.id
      },
    serChnage(item) {
      this.$emit("change", item);
    },
    changeItem() {
      this.$emit("update:serviceid", this.service_id);
    },
    searchOrgName(query) {
      let url = "/user/venue/server/queryListPage";
      let data = {
        name:query || null,
        pageSize: 10,
        currentPage: 1,
        ...this.condition,
      };
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.service_list = res.data.data;
        })
    },
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const Selects = this.$refs
          if (Selects.agentSelect) {
            const input = Selects.agentSelect.$el.querySelector('.el-input__inner')
            input.removeAttribute('readonly')
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>